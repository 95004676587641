<template>
  <section class="section">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
          <AccountBreadcrumbRoute v-bind="{ title }" />
        </div>
        <div class="column is-12">
          <slot />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Account',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  components: {
    AccountBreadcrumbRoute: () =>
      import('../components/history/AccountBreadcrumbRoute')
  }
}
</script>
